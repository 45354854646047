import { useUserBalance } from '@/hooks/Balance'
import { useClickOutside, useScrollable } from '@/hooks/Index'
import { floatAddition, formatWithGBP } from '@/utils/FloatNumber'
import { svgs } from '@/utils/Images'
import { useRouter } from 'next/router'
import { Dispatch, FC, SetStateAction, useRef } from 'react'

export const UserDropdown: FC<{
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
}> = ({ isOpen, setIsOpen }) => {
  // const { navigation: navStr, user: userStr, auth: authStr, wallet: walletStr } = useLanguage()
  // const { isDesktop } = useLayout()
  const router = useRouter()

  // const { user, logout } = useAuth({ middleware: 'auth' })

  const wrapperRef = useRef(null)
  useClickOutside(wrapperRef, () => setIsOpen(false))
  useScrollable(isOpen)

  // const { data: cash } = useSWRImmutable(API_URL.userCashBalance, appUtils.fetcher)
  // const { data: credit } = useSWRImmutable<UserBalance>(API_URL.userCreditBalance, appUtils.fetcher)
  // const cashTotal = useMemo(() => floatAddition(cash?.amount ?? 0, cash?.pending_amount ?? 0), [cash?.amount, cash?.pending_amount])
  // const creditTotal = useMemo(
  //   () => floatAddition(credit?.available_amount ?? 0, credit?.pending_amount ?? 0),
  //   [credit?.available_amount, credit?.pending_amount]
  // )

  const { cashTotal, creditTotal } = useUserBalance(true)

  const ref = useRef()

  // const tabs = [
  //   { path: '/user/dashboard', title: navStr.account_centre, testid: 'btn-account-accCentre' },
  //   { path: '/user/wallet', title: userStr.my_wallet, testid: 'btn-drop-wallet' },
  //   { path: '/user/competitions', title: userStr.my_competitions, testid: 'btn-drop-myComp' },
  //   { path: '/user/orders', title: userStr.my_orders, testid: 'btn-drop-order' },
  //   { path: '/user/details', title: navStr.my_account, testid: 'btn-drop-myAcc' },
  //   { path: '/user/addresses', title: userStr.my_address, testid: 'btn-drop-myAddr' },
  //   { path: null, title: authStr.logout, testid: 'btn-drop-logout' },
  // ]

  return (
    <>
      <div ref={wrapperRef} className='relative ml-auto'>
        <button
          ref={ref}
          className={`flex items-center rounded border bg-transparent px-1 py-1 outline-none md:px-2 ${
            isOpen ? 'border-amber-v1 text-amber-v1' : 'border-white/100 text-white/100 hover:border-cyan-v1 hover:text-cyan-v1'
          }`}
          onClick={() => {
            //  setIsOpen(prev => !prev)
            if (!router.pathname.startsWith('user/dashboard')) {
              router.push('/user/dashboard')
            }
          }}
          data-testid='btn-top-account'
        >
          {formatWithGBP(floatAddition(Number(cashTotal), Number(creditTotal)))}
          <svg className='ml-1 h-6 w-6 cursor-pointer' fill='none' viewBox='6 6 24 24' stroke='currentColor'>
            {svgs.user}
          </svg>
        </button>
        {/* <div
          className={`transition-all duration-500 ${
            isDesktop
              ? 'absolute right-0 top-full mt-2 w-80 rounded border-2 border-amber-v1 bg-white/100 shadow-lg'
              : 'fixed left-0 top-16 z-50 h-full w-full bg-stone-900/70'
          } ${isOpen ? 'translate-y-[0px] opacity-100' : 'pointer-events-none -translate-y-[10px] opacity-0'}`}
        >
          {tabs.map((tab, i) => {
            const selected = router.pathname.startsWith(tab.path)
            return (
              <Fragment key={`account-tab-${i}`}>
                {i == 0 ? (
                  <div className='bg-white/100 pb-2'>
                    <button
                      className={`text-grey-900 flex w-full items-center border-b border-stone-300 bg-amber-v1 py-4 px-4 text-sm ${
                        selected ? 'font-semibold' : 'font-normal'
                      }`}
                      data-testid={tab.testid}
                    >
                      <span
                        onClick={() => {
                          router.push(tab.path)
                          setIsOpen(false)
                        }}
                        className='w-full text-left hover:font-semibold'
                      >
                        {tab.title}
                      </span>
                      <span onClick={() => setIsOpen(false)} className='h-3 cursor-pointer pl-4 text-black hover:text-error'>
                        {svgs.cross}
                      </span>
                    </button>
                    <div className='mt-4 px-4 text-sm'>
                      {userStr.hello},{' '}
                      <span className='font-semibold'>{user?.display_name ?? user?.profile?.name ?? user?.email.substring(0, user?.email.indexOf('@'))}</span>
                    </div>
                    <div className='mt-2 flex items-center justify-between px-4'>
                      <span className='text-sm'>{walletStr.cash_balance}</span>
                      <span className='font-semibold'>{formatWithGBP(cashTotal)}</span>
                    </div>
                    <div className='mt-2 flex items-center justify-between px-4'>
                      <span className='text-sm'>{walletStr.site_credit}</span>
                      <span className='font-semibold'>{formatWithGBP(creditTotal)}</span>
                    </div>
                    <div className='mt-2 flex items-center justify-evenly px-4'>
                      {[
                        { link: '/user/wallet/withdraw', label: walletStr.withdraw, testid: 'btn-drop-withdraw' },
                        { link: '/user/wallet/top-up', label: walletStr.buy_site_credit, testid: 'btn-drop-buyCredit' },
                      ].map(item => (
                        <button
                          key={item.link}
                          onClick={() => {
                            router.push(item.link)
                            setIsOpen(false)
                          }}
                          className='btn-rd-outline btn-hover-amber px-auto h-10 w-full flex-1 first:mr-2 last:ml-2'
                          data-testid={item.testid}
                        >
                          {item.label}
                        </button>
                      ))}
                    </div>
                  </div>
                ) : (
                  <button
                    onClick={() => {
                      if (i !== tabs.length - 1) {
                        router.push(tab.path)
                        setIsOpen(false)
                      } else {
                        logout()
                        setIsOpen(false)
                      }
                    }}
                    className={`flex w-full items-center justify-between border-t border-neutral-300 p-4 text-sm last:rounded-b-lg hover:font-semibold ${
                      i === tabs.length - 1 ? 'text-error' : 'text-black'
                    } ${selected ? 'bg-amber-v1 font-semibold' : 'bg-white/100 font-normal'}`}
                    data-testid={tab.testid}
                  >
                    {tab.title}
                    <svg className='-rotate-90' width='9' height='7' viewBox='0 0 9 7' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <path
                        d='M5.26799 6.07909C4.86821 6.55848 4.13179 6.55848 3.73201 6.0791L0.238902 1.89046C-0.304217 1.2392 0.158881 0.249999 1.00689 0.249999L7.99311 0.25C8.84112 0.25 9.30422 1.2392 8.7611 1.89046L5.26799 6.07909Z'
                        fill='black'
                      />
                    </svg>
                  </button>
                )}
              </Fragment>
            )
          })}
        </div> */}
      </div>
    </>
  )
}
export default UserDropdown
