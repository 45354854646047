import { useAuth } from '@/hooks/Auth'
import { svgs } from '@/utils/Images'
import { Action0 } from '@/utils/Types'
import { useLanguage, useLayout, useMyCart } from 'hooks/Contexts'
import Head from 'next/head'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { FC, ImgHTMLAttributes, ReactElement, useEffect, useMemo, useRef, useState } from 'react'
import CaretIcon from '../CaretIcon'
import UserDropdown from '../UserDropdown'

export default function Navbar(): ReactElement {
  const { navigation: navStr, auth: authStr } = useLanguage()
  const { isDesktop } = useLayout()
  const router = useRouter()
  const { user, isMutating } = useAuth()

  const cartCount = useMyCart()

  /* Prevent two menus to be open at the same time */
  const sidebarCtrl = useState<boolean>(false)
  const [showSidebar] = sidebarCtrl
  const setShowSidebar = (newVal?: boolean) => {
    // close user dropdown if to open sidebar
    if (newVal) {
      setShowUserDropdown(false)
    }
    sidebarCtrl[1](newVal)
  }
  const toggleSidebar = () =>
    sidebarCtrl[1](prev => {
      // close user dropdown if to open sidebar
      if (!prev) {
        setShowUserDropdown(false)
      }
      return !prev
    })

  const userDropdownCtrl = useState<boolean>(false)
  const setShowUserDropdown = (newVal?: boolean) => {
    // close sidebar if to open user dropdown
    if (newVal) {
      setShowSidebar(false)
    }
    userDropdownCtrl[1](newVal)
  }
  const isCompDetailPage = useMemo(() => router.pathname == '/competitions/[shortName]', [router.pathname])
  return (
    <>
      <Head>
        <title>{navStr.app_name}</title>
        <link rel='icon' href='/assets/favicons/favicon.ico' />
        <meta name='robots' content={navStr.meta.robots} key='robots' />
        <meta property='og:site_name' content={navStr.app_name} />
        <meta property='og:locale' content='en_GB' />
        {isCompDetailPage ? null : (
          <>
            <meta name='description' content={navStr.meta.description} key='description' />
            <meta name='og:description' content={navStr.meta.description} key='og:description' />
            <meta name='title' content={navStr.meta.title} key='title' />
            <meta property='og:title' content={navStr.meta.title} key='og:title' />
            <meta property='og:url' content={navStr.meta.domain + router.pathname} key='og:url' />
            <meta property='og:type' content='website' key='og:type' />
          </>
        )}
        <meta name='twitter:title' content={navStr.meta.title} key='twitter:title' />
        <meta name='twitter:description' content={navStr.meta.description} key='twitter:description' />
        <meta name='twitter:card' content='summary_large_image' />
      </Head>
      <div
        className={`left-0 top-0 z-[52] flex h-16 w-screen bg-[linear-gradient(90deg,#1F1F1F_7%,#333333_84%)] bg-no-repeat md:h-20 ${
          isDesktop && isCompDetailPage ? '' : 'fixed'
        }`}
      >
        <div className='main-container'>
          <Link href='/' passHref>
            <a
              onClick={() => {
                setShowSidebar(false)
                setShowUserDropdown(false)
              }}
              className='mr-auto md:mr-0'
            >
              <Logo />
            </a>
          </Link>

          {/* menu: as row in desktop, as dropdown in mobile  */}
          <nav
            className={
              isDesktop
                ? 'ml-2 mr-auto flex flex-row items-center lg:ml-4 lg:gap-x-2 xl:gap-x-4'
                : `fixed left-0 top-16 z-[53] h-full w-full rounded-b-lg bg-stone-900/70 transition-all duration-500 ${
                    showSidebar ? 'translate-y-[0px] opacity-100' : 'pointer-events-none -translate-y-[10px] opacity-0'
                  }`
            }
            onClick={e => {
              // click outside to close
              if (e?.target?.['nodeName']?.toLowerCase?.() == 'nav' && showSidebar) {
                setShowSidebar(false)
              }
            }}
          >
            <NavbarMenuLink title={navStr.competitions} link='/competitions' onClick={toggleSidebar} />
            <NavbarMenuLink title={navStr.draws} link='/draws' onClick={toggleSidebar} />
            <NavbarMenuLink title={navStr.our_winners} link='/winners' onClick={toggleSidebar} />
            <NavbarMenuLink title={navStr.entry_list} link='/entries' onClick={toggleSidebar} />
            {isDesktop || user ? null : <NavbarMenuLink title={authStr.login + ' / ' + authStr.register} link='/login' onClick={toggleSidebar} />}
          </nav>

          {/* go to user dashboard if logged  */}
          {isMutating ? null : user ? (
            <UserDropdown isOpen={router.pathname.startsWith('/user/dashboard')} setIsOpen={setShowUserDropdown} />
          ) : (
            <div className='ml-auto flex'>
              {/* go to auth if no user logged  */}
              <button
                type='button'
                onClick={() => {
                  router.push(user ? '/user/dashboard' : '/login')
                  setShowSidebar(false)
                  setShowUserDropdown(false)
                }}
                className='btn-rd-outline btn-hover-amber hidden h-7 w-24 text-sm text-white/100 xs:block md:h-8 xl:w-40'
                data-testid='btn-top-login-start'
              >
                {authStr.login}
              </button>
              <button
                type='button'
                onClick={() => {
                  router.push('/register')
                  setShowSidebar(false)
                  setShowUserDropdown(false)
                }}
                className='btn-rd-amber btn-hover-amber hidden h-7 w-24 text-sm sm:ml-4 sm:block md:hidden md:h-8 lg:block xl:w-40'
                data-testid='btn-top-register-start'
              >
                {authStr.register}
              </button>
            </div>
          )}

          {/* cart */}
          <button
            className='group relative ml-4 flex items-center'
            onClick={() => {
              router.push('/cart')
              setShowSidebar(false)
              setShowUserDropdown(false)
            }}
            data-testid='btn-top-basket'
          >
            <svg
              className={`h-6 w-6 cursor-pointer ${router.pathname.includes('/cart') ? 'text-amber-v1' : 'text-white/100 group-hover:text-cyan-v1'}`}
              fill='none'
              viewBox='3 3 26 26'
              stroke='currentColor'
            >
              {svgs.cart}
            </svg>
            {cartCount?.items > 0 && (
              <span className='absolute -right-[7px] -top-[7px] rounded-full border border-solid border-red-500 bg-red-500 px-1 text-xs font-medium text-white/100'>
                {cartCount.items}
              </span>
            )}
          </button>

          <button
            className='ml-4 block cursor-pointer border-transparent bg-transparent text-xl leading-none text-cyan-v1 outline-none focus:outline-none md:hidden'
            type='button'
            onClick={toggleSidebar}
          >
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='5 5 22 22' className='h-6 w-6 fill-none text-cyan-v1'>
              <path d='M6.66699 9.33301H25.3337' stroke='currentColor' strokeWidth='2' strokeLinecap='round' />
              <path d='M6.66699 16H25.3337' stroke='currentColor' strokeWidth='2' strokeLinecap='round' />
              <path d='M6.66699 22.667H25.3337' stroke='currentColor' strokeWidth='2' strokeLinecap='round' />
            </svg>
          </button>
        </div>
      </div>
    </>
  )
}

const Logo: FC<ImgHTMLAttributes<HTMLImageElement>> = props => {
  const { breakpoint } = useLayout()
  const [small, setSmall] = useState<boolean>(false)
  const ref = useRef(null)
  useEffect(() => {
    if (breakpoint) {
      setSmall(ref.current?.clientWidth < (breakpoint == 'md' ? 94.5 : 156))
    }
  }, [breakpoint, ref.current?.clientWidth])
  return (
    <div ref={ref} className='mr-auto flex cursor-pointer items-center gap-x-2 md:mr-0 md:border-r md:border-cyan-v1 md:pr-4 lg:gap-x-4'>
      <img src='/assets/logo_lg.gif' alt='RKINGS' className='pointer-events-none h-12 w-10 object-cover object-left md:h-11 md:w-[38px] lg:h-12 lg:w-10' />
      <img
        src='/assets/brand.svg'
        alt='RKingsCompetitions'
        className={`pointer-events-none h-8 w-auto md:h-7 lg:h-8 ${small ? 'opacity-0' : 'opacity-100'}`}
        {...props}
      />
    </div>
  )
}

/**
 * A Link that can be displayed in the menu.
 * @param {object} props
 */
const NavbarMenuLink: FC<{ title: string; link: string; onClick: Action0 }> = ({ title, link, onClick }) => {
  const { isDesktop } = useLayout()
  const { asPath } = useRouter()
  const selected = useMemo(() => asPath.startsWith(link), [asPath, link])
  return (
    <Link href={link} passHref>
      <a
        onClick={onClick}
        className={`flex cursor-pointer items-center whitespace-nowrap ${
          isDesktop
            ? 'btn-nav btn-hover-amber h-10 justify-center px-[14px] text-sm font-medium lg:px-6 ' + (selected ? 'text-amber-v1' : 'text-white/100')
            : 'w-full justify-between border-b border-neutral-300 bg-white/100 px-2 py-4 text-lg last:rounded-b-lg last:border-0 sm:px-4'
        }`}
      >
        {title}
        {isDesktop ? null : <CaretIcon contentOpen className='h-5 w-5 !rotate-180' />}
      </a>
    </Link>
  )
}
